<template>
  <div>
    <div class="main-box">
      <div class="title-main">Device Config</div>
      <div class="flex-row-start-between line-box">
        <div><!--占位--></div>
        <a-button @click="refreshAll">Refresh All</a-button>
      </div>
      <div>
        <pre>

        </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountId: null,
      tenantId: null
    };
  },
  created() {
    this.accountId = this.$route.params.accountId;
    this.tenantId = this.$route.params.tenantId;
  },
  methods: {
    refreshAll() {

    }
  }
};
</script>

<style lang="scss" scoped>

</style>

